import * as React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

class Thumbnail extends React.Component {
    constructor(props) {
        super(props);
        this.handleImageLoaded = this.handleImageLoaded.bind(this);
        this.handleImageLoadError = this.handleImageLoadError.bind(this);
        this.state = {
            show_loading: true,
            img_loaded: false,
        };
        this.imageRef = React.createRef();
        this.spinnerRef = React.createRef();
    }

    componentDidMount() {
        if (!this.imageRef.current.complete) {
            this.imageRef.current.addEventListener("load", this.handleImageLoaded);
            this.imageRef.current.addEventListener("error", this.handleImageLoadError);
        } else {
            this.setState({
                show_loading: false,
                img_loaded: true,
            })
            this.imageRef.current.style.opacity = 1;
        }
    }

    handleImageLoaded() {
        this.setState({
            show_loading: false,
            img_loaded: true,
        })
        this.imageRef.current.style.opacity = 1;
    }

    handleImageLoadError() {
        console.log(`Unable to load image.`);
        this.setState({
            show_loading: false,
        })
    }

    render() {
        return (
            <div
                className={this.props.className}
                // style={(this.state.img_loaded) ? {display: "block"} : {display: "none"}}
            >
                {this.state.show_loading &&
                    <div className="page-link__thumbnail__status">
                        <div className="page-link__thumbnail__icon">
                            <FontAwesomeIcon className="fa-icon fa-spin" icon={["far", "compass"]} />
                        </div>
                    </div>
                }
                {this.props.linkUrl !== null
                    ?
                    <Link
                        to={this.props.linkUrl}
                        className="page-link__thumbnail__link"
                    >
                        <img
                            ref={this.imageRef}
                            src={this.props.imgSrc} 
                            alt={this.props.imgAlt}
                            className="page-link__thumbnail__img"
                            onLoad={this.handleImageLoaded}
                            onError={this.handleImageLoadError}
                            style={{opacity: 0}}
                        />
                    </Link>
                    :
                    <img
                        ref={this.imageRef}
                        src={this.props.imgSrc} 
                        alt={this.props.imgAlt}
                        className="page-link__thumbnail__img"
                        onLoad={this.handleImageLoaded}
                        onError={this.handleImageLoadError}
                        style={{opacity: 0}}
                    />
                } 
            </div>
        )
    }
}

export default Thumbnail;