import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../../components/layout.js"
import Description from "../../components/description.js"
import Thumbnail from "../../components/thumbnail.js"

const Art = ({ data }) => {
    return(
        <Layout pageTitle="Art and Other Pursuits">
            <div className="row">
                <div className="col-12 mb-3">
                    <p>Examples of personal projects.</p>
                </div>
            </div>
            {data.projects.edges.map(({ node }) => (
                <article className="row teaser" key={node.fields.slug}>
                    <div className="d-none d-sm-block col-sm-4 col-md-4 col-lg-3">
                        {node.relationships.media_graphic.length > 0 &&
                            <Thumbnail
                                className={`page-link__thumbnail ${(node.relationships.media_graphic[0].media_image.width > node.relationships.media_graphic[0].media_image.height) ? "img-landscape" : "img-portrait"}`}
                                linkUrl={node.path.alias}
                                imgSrc={node.relationships.media_graphic[0].relationships.media_image_file.file.url}
                                imgAlt={node.relationships.media_graphic[0].media_image.alt}
                            />
                        // <div className={`page-link__thumbnail ${(node.relationships.media_graphic[0].media_image.width > node.relationships.media_graphic[0].media_image.height) ? "img-landscape" : "img-portrait"}`}>
                            //     <Link to={node.path.alias}>
                            //         <img src={node.relationships.media_graphic[0].relationships.media_image_file.file.url} alt={node.relationships.media_graphic[0].media_image.alt} />
                            //     </Link>
                            // </div>
                        }
                    </div>
                    <div className="col-12 col-sm-8 col-md-8 col-lg-9">
                        <h2 className="page-link--slug m-0"><Link to={node.path.alias}>{node.title}</Link></h2>
                        {/* <div className="mt-3" dangerouslySetInnerHTML={{ __html: node.description.html }}></div> */}
                        <Description className="mt-3" html={node.description.html} />
                    </div>
                </article>
            ))}
        </Layout>
    )
}

export default Art

export const query = graphql`
    query {
        projects: allNodeActivity (
            sort: { order: DESC, fields: field_sorting_weight }
        ) {
            edges {
                node {
                    title
                    description: field_preamble_formatted {
                        html: processed
                    }
                    fields {
                        slug
                    }
                    path {
                        alias
                    }
                    relationships {
                        media_graphic: field_media_photo_graphic {
                            media_image: field_media_image {
                                alt
                                height
                                title
                                width
                            }
                            relationships {
                                media_image_file: field_media_image {
                                    file: localFile {
                                        url: publicURL
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`